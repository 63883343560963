import { isEmptyValue } from 'shared/lib/text';
import { useMemo } from 'react';
import { evaluate, evaluateRangeExclusive, isSupportedOperation, SupportedOperation } from 'shared/lib/math';
import TextDisplay from './TextDisplay';
import {
  DiffArrayChangeSymbol,
  FieldInputNumberBlock,
  RangeDiffElement,
  RuleDiffElement,
  RunFieldInputNumberBlock,
} from 'shared/lib/types/views/procedures';
import diffUtil from '../../../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

interface NumberDisplayProps {
  block: FieldInputNumberBlock;
  referenceId?: string;
  referenceDiffChangeState?: DiffArrayChangeSymbol;
}

const NumberDisplay = ({ block, referenceId, referenceDiffChangeState }: NumberDisplayProps) => {
  const recordedValue = useMemo((): number | undefined => {
    return (block as RunFieldInputNumberBlock).recorded?.value;
  }, [block]);

  const version = useMemo(
    () => (referenceDiffChangeState === ARRAY_CHANGE_SYMBOLS.REMOVED ? 'old' : 'new'),
    [referenceDiffChangeState]
  );

  const displayPass = useMemo(() => {
    const rule = block.rule;
    if (!rule || isEmptyValue(rule)) {
      return null;
    }
    if (isEmptyValue(recordedValue)) {
      return null;
    }
    const op = block.rule?.op as string;
    const value = recordedValue as number;
    if (isSupportedOperation(op)) {
      const operand = parseFloat(rule.value as string);
      return evaluate(value, operand, op as SupportedOperation);
    } else if (op === 'range') {
      const min = rule.range?.min as string;
      const max = rule.range?.max as string;
      if (isEmptyValue(min) || isEmptyValue(max)) {
        return null;
      }

      const range = {
        min: parseFloat(min),
        max: parseFloat(max),
      };
      return evaluateRangeExclusive(value, range);
    }
    return null;
  }, [block.rule, recordedValue]);

  const displayRule = useMemo(() => {
    const rule = diffUtil.getFieldValue(block, 'rule') as RuleDiffElement;
    if (!rule || isEmptyValue(rule) || !rule.op || isEmptyValue(sharedDiffUtil.getDiffValue(rule, 'op', version))) {
      return null;
    }
    // Ranges define a `min` and `max` instead of a `value`
    if (sharedDiffUtil.getDiffValue(rule, 'op', version) === 'range') {
      const range = diffUtil.getFieldValue(rule, 'range') as RangeDiffElement;
      if (
        !range ||
        isEmptyValue(range) ||
        isEmptyValue(sharedDiffUtil.getDiffValue(range, 'min', version)) ||
        isEmptyValue(sharedDiffUtil.getDiffValue(range, 'max', version))
      ) {
        return null;
      }
      return `${sharedDiffUtil.getDiffValue(range, 'min', version)} < Value < ${sharedDiffUtil.getDiffValue(
        range,
        'max',
        version
      )}`;
    }
    // Standard block rules define a single `value`
    if (isEmptyValue(sharedDiffUtil.getDiffValue(rule, 'value', version))) {
      return null;
    }
    return `${sharedDiffUtil.getDiffValue(rule, 'op', version)} ${sharedDiffUtil.getDiffValue(rule, 'value', version)}`;
  }, [block, version]);

  return (
    <div className="flex flex-row">
      <TextDisplay block={block} referenceId={referenceId} />
      {block.inputType === 'number' && (displayRule || displayPass !== null) && (
        <div className="mt-1 flex w-56 items-start justify-end">
          {displayPass !== null &&
            (displayPass ? (
              <span className="ml-2 text-green-600">PASS </span>
            ) : (
              <span className="ml-2 text-red-600">FAIL </span>
            ))}
          <span className="whitespace-nowrap">{displayRule && <span className="mx-2">({displayRule})</span>}</span>
        </div>
      )}
    </div>
  );
};

export default NumberDisplay;
