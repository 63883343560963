import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useHistory, useParams, Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { E_ALREADY_LOCKED, Mutex, tryAcquire } from 'async-mutex';
import { useStore } from 'react-redux';
import useEventListener from '@use-it/event-listener';
import throttle from 'lodash.throttle';
import { FEATURE_EDIT_PROCEDURE_OPENS_EXPANDED } from '../config';
import { procedureReviewPath, procedureViewPath } from '../lib/pathUtil';
import REFRESH_TRY_AGAIN_MESSAGE from '../lib/messages';
import attachmentUtil from '../lib/attachmentUtil';
import procedureUtil from '../lib/procedureUtil';
import revisionsUtil from '../lib/revisions';
import apm from '../lib/apm';
import { getRedlinesWithValidRedlineIds, getScrollEntries, REDLINE_TYPE } from '../lib/redlineUtil';
import { MILLISECONDS_PER_SECOND } from '../lib/datetime';
import validateUtil from '../lib/validateUtil';

import { FormProcedure } from '../components/FormProcedure';
import NotFound from '../components/NotFound';
import ProcedureEditLocked from '../components/ProcedureEditLocked';
import EditToolbar from '../components/EditToolbar';
import Run, { PREVIEW_MODE } from './Run';

import { selectProcedures } from '../contexts/proceduresSlice';
import { useSettings } from '../contexts/SettingsContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useUserInfo } from '../contexts/UserContext';
import { useMixpanel } from '../contexts/MixpanelContext';

import useExpandCollapse from '../hooks/useExpandCollapse';
import useStateHistory from '../hooks/useStateHistory';
import useLocationState from '../hooks/useLocationState';
import useMasterProcedureListHelpers from '../hooks/useMasterProcedureListHelpers';

import { RunContextProvider } from '../contexts/RunContext';
import { SelectionContextProvider } from '../contexts/Selection';
import { ProcedureEditProvider } from '../contexts/ProcedureEditContext';
import ReviewSettingsModal from '../components/Review/ReviewSettingsModal';
import SidebarLayout from '../elements/SidebarLayout';
import EditSidebar from '../components/EditSidebar';
import useUrlScrollTo from '../hooks/useUrlScrollTo';
import useLocationParams from '../hooks/useLocationParams';
import useAutoProcedureId from '../hooks/useAutoProcedureId';
import RunBatchProcedureModal from '../components/BatchSteps/RunBatchProcedureModal';
import { isProcedureWithBatchSteps } from '../lib/batchSteps';
import EditConflictModal from '../components/EditConflictModal';
import { shouldShowLock } from '../lib/lock';

const KEY_S = 's';

export const SUBMISSION = {
  SAVE: 'save',
  AUTOSAVE: 'autosave',
  REVIEW: 'review',
  PREVIEW: 'preview',
};

/**
 * @type {{
 *  isSubmitting: boolean,
 *  type: null | typeof SUBMISSION[keyof typeof SUBMISSION],
 *  procedureToSubmit?: null | import('shared/lib/types/views/procedures').Draft,
 *  started?: boolean,
 *  settings?: {reviewTypeId?: string},
 * }}
 */
export const INITIAL_SUBMISSION_STATE = {
  isSubmitting: false,
  type: null,
  started: false,
  procedureToSubmit: null,
  settings: {},
};

const INITIAL_ERROR_STATE = {
  errors: {},
  hasErrors: false,
  firstErrorField: {},
};

const AUTOSAVE_PERIOD_MS = 30 * MILLISECONDS_PER_SECOND;
const AUTOSAVE_DELAY_MS = MILLISECONDS_PER_SECOND;

const ProcedureEdit = () => {
  const { id } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const stateHistory = useStateHistory();
  const locationState = useLocationState();
  const store = useStore();
  const { syncMasterProcedureList } = useMasterProcedureListHelpers();
  const { tryUpdateDocWithUniqueId } = useAutoProcedureId();
  const [showBatchRunModal, setShowBatchRunModal] = useState(false);

  const { present, hasPast, hasFuture, redoState, updatePresentAndAddToPast, undoState } = stateHistory;

  // This state should be kept in sync, hence is kept together in one object
  const [docs, setDocs] = useState({
    procedure: null,
    redlines: null,
    snippets: null,
    externalItems: null,
  });
  const [dataLoading, setDataLoading] = useState(true);
  const [redlinesLoading, setRedlinesLoading] = useState(true);
  const [submissionErrors, setSubmissionErrors] = useState(
    /**
     * @type {string | null}
     */
    (null)
  );
  const [submission, setSubmission] = useState(INITIAL_SUBMISSION_STATE);
  const [procedureErrors, setProcedureErrors] = useState(INITIAL_ERROR_STATE);

  const [lastSavedTime, setLastSavedTime] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [currentTab, setCurrentTab] = useState('list');
  const [reviewSettings, setReviewSettings] = useState(null);
  const [showLock, setShowLock] = useState(false);
  const [showConflictModal, setShowConflictModal] = useState(false);

  const { services, currentTeamId } = useDatabaseServices();
  const { mixpanel } = useMixpanel();
  const isMounted = useRef(true);
  const hasOpenedCollapsed = useRef(false);
  const procedureRev = useRef(undefined);
  const { userInfo } = useUserInfo();
  const userId = userInfo.session.user_id;
  const expandCollapse = useExpandCollapse();
  const { setAllExpanded, setIsCollapsed, setIsCollapsedMap } = expandCollapse;
  const { config, getSetting } = useSettings();
  const showRedlineValidation = getSetting('disable_procedure_release_redlines_acknowledged', false);
  const location = useLocation();
  const { searchParams } = useLocationParams(location);

  const submissionMutex = useRef(new Mutex());
  const [releaseSubmissionMutex, setReleaseSubmissionMutex] = useState(null);
  const [submissionLocked, setSubmissionLocked] = useState(false);
  const [scheduledValidateReview, setScheduledValidateReview] = useState(false);
  const [scheduledPreview, setScheduledPreview] = useState(false);
  const [shouldSaveUsingShortcut, setShouldSaveUsingShortcut] = useState(false);
  const [shouldScrollToError, setShouldScrollToError] = useState(false);
  const [procedureLoading, setProcedureLoading] = useState(true);
  const [activeSidebarTabs, setActiveSidebarTabs] = useState([0, 1]);
  const [isNavigatingInternally, setIsNavigatingInternally] = useState(false);

  const updateActiveSidebarTabs = useCallback((event) => {
    setActiveSidebarTabs(event.index);
  }, []);

  const onAfterScroll = () => {
    setIsNavigatingInternally(false);
  };

  const { onScrollToRefChanged, onScrollToId } = useUrlScrollTo({
    setIsCollapsed,
    procedure: docs.procedure,
    searchParams,
    onAfterScroll,
  });

  /**
   * Start master procedure list sync, in-case user visits this page directly without visiting the master procedure list.
   * We need the msetScheduledReviewaster procedure list somewhat in-sync for a few checks:
   * * Procedure code duplicate validation
   * * Linked procedure section (if this procedure has a section that is linked in other procedures,
   * * we show a user a warning when they try to delete it)
   */
  useEffect(() => {
    syncMasterProcedureList();
  }, [syncMasterProcedureList]);

  useEffect(() => {
    if (locationState?.goToTab) {
      setCurrentTab(locationState.goToTab);
    }
  }, [locationState]);

  const mixpanelTrack = useCallback(
    (name, options) => {
      if (mixpanel && name) {
        mixpanel.track(name, options);
      }
    },
    [mixpanel]
  );

  const handleSubmissionMutexRelease = (callback) => {
    return (release) => {
      setSubmissionLocked(true);
      callback();
      setReleaseSubmissionMutex(() => release); // Functional setState is needed to escape closure and make the release function available after callWithSubmissionMutexIfAvailable/callWithSubmissionMutexWaitForAvailable returns
    };
  };

  /**
   * Attempt to acquire the submission mutex, and if it is locked, do nothing,
   * else lock the mutex, run the callback, and set the release function so it can be later used to unlock the mutex.
   *
   * @param callback - the function to run after the mutex is locked
   */
  const callWithSubmissionMutexIfAvailable = useCallback((callback) => {
    tryAcquire(submissionMutex.current)
      .acquire()
      .then(handleSubmissionMutexRelease(callback))
      .catch((error) => {
        if (error !== E_ALREADY_LOCKED) {
          throw error;
        }
      });
  }, []);

  /**
   * Attempt to acquire the submission mutex, and if it is locked, wait for the mutex to unlock (and when it is available do what is in the following 'else` clause),
   * else lock the mutex, run the callback, and set the release function so it can be later used to unlock the mutex.
   *
   * @param callback - the function to run after the mutex is locked
   */
  const callWithSubmissionMutexWaitForAvailable = useCallback((callback) => {
    submissionMutex.current.acquire().then(handleSubmissionMutexRelease(callback));
  }, []);

  const invokedAutoSaveTimerRef = useRef(null);

  /*
   * Throttle autosave to at most once every AUTOSAVE_PERIOD_MS.
   * Add a delay every time autosave runs so that if Review is clicked,
   * the first autosave does not run right before the review is submitted.
   * It is still possible that even with the delay, a subsequent autosave could be running
   * when Review is clicked, but there is additional logic to handle scheduled reviews in those cases.
   */
  const submitAutoSave = throttle(() => {
    invokedAutoSaveTimerRef.current = setTimeout(() => {
      /*
       * If an autosave is attempted while submissionMutex is locked, that means a save/review is ongoing,
       * and there will be no need for this attemptyed autosave. If an edit is made during the autosave, the isDirty flag will be set and another
       * autosave will be triggered.
       * Therefore the mutex-protected autosave callback is not run if submissionMutex is locked.
       */
      callWithSubmissionMutexIfAvailable(() => {
        setSubmission({
          isSubmitting: true,
          type: SUBMISSION.AUTOSAVE,
        });
      });
    }, AUTOSAVE_DELAY_MS);
  }, AUTOSAVE_PERIOD_MS);

  const autoSaveRef = useRef(submitAutoSave);

  const autoSave = useCallback(() => {
    autoSaveRef.current();
  }, []);

  /*
   * If a change is made when a save is not in progress, run a throttled autosave.
   * If a change is made when a save is in progress, do nothing until the current save is complete,
   *  and then when the save is complete, run a throttled autosave.
   */
  useEffect(() => {
    if (!submissionLocked && isDirty) {
      autoSave();
    }
  }, [submissionLocked, isDirty, autoSave]);

  const handleOnSave = useCallback(
    (procedure) => {
      /*
       * If a save is attempted while submissionMutex is locked, that means a save/review is ongoing,
       * and there will be no need for this attempted save. If an edit is made during the save, the isDirty flag will be set and an
       * autosave will be triggered.
       * Therefore the mutex-protected save callback is not run if submissionMutex is locked.
       */
      callWithSubmissionMutexIfAvailable(() => {
        setSubmission({
          isSubmitting: true,
          type: SUBMISSION.SAVE,
          procedureToSubmit: procedure,
        });
      });
    },
    [callWithSubmissionMutexIfAvailable, setSubmission]
  );

  const handleOnSaveShortcut = useCallback(() => {
    if (!submissionLocked) {
      // Protect against rapid calls to CMD+S
      mixpanelTrack('Draft Saved', { Trigger: 'Keyboard Shortcut' });
      handleOnSave();
    }
  }, [submissionLocked, mixpanelTrack, handleOnSave]);

  useEffect(() => {
    if (shouldSaveUsingShortcut) {
      handleOnSaveShortcut();
      setShouldSaveUsingShortcut(false);
    }
  }, [shouldSaveUsingShortcut, handleOnSaveShortcut]);

  const syncFormToProcedure = (e) => e.target.blur();

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === KEY_S && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        syncFormToProcedure(e);
        setShouldSaveUsingShortcut(true);
      }
    },
    [setShouldSaveUsingShortcut]
  );
  useEventListener('keydown', onKeyDown);

  const handleOnSaveButton = useCallback(() => {
    mixpanelTrack('Draft Saved', { Trigger: 'Toolbar Button' });
    handleOnSave();
  }, [mixpanelTrack, handleOnSave]);

  const handleOnSaveForm = useCallback(
    (procedure) => {
      mixpanelTrack('Draft Saved', { Trigger: 'Form Load' });
      handleOnSave(procedure);
    },
    [mixpanelTrack, handleOnSave]
  );

  const getProcedureAndUpdateRev = useCallback(
    async (procedureId) => {
      const procedure = await services.procedures.getProcedure(procedureId);
      procedureRev.current = procedure._rev;
      return procedure;
    },
    [services.procedures]
  );

  const onSaveSuccess = useCallback(
    (procedureId) => {
      if (!isMounted.current) {
        return;
      }
      setSubmissionErrors(null);
      setLastSavedTime(new Date());

      const submissionType = submission.type;
      return getProcedureAndUpdateRev(procedureId)
        .then(() => {
          hasOpenedCollapsed.current = true;

          if (submissionType === SUBMISSION.SAVE) {
            setReviewSettings(null);
            setProcedureErrors(INITIAL_ERROR_STATE);
          }
        })
        .catch((error) => {
          setSubmissionErrors(REFRESH_TRY_AGAIN_MESSAGE);
          apm.captureError(error);
        });
    },
    [submission.type, getProcedureAndUpdateRev]
  );

  const checkForConflict = useCallback((error) => {
    if (error.response?.status === 409) {
      setShowConflictModal(true);
      return true;
    }
    return false;
  }, []);

  const onSaveFailure = useCallback(
    (error) => {
      setIsDirty(true);
      const hasConflict = checkForConflict(error);
      if (!hasConflict) {
        setSubmissionErrors(REFRESH_TRY_AGAIN_MESSAGE);
      }
      apm.captureError(error ?? 'Error during save in ProcedureEdit.');
    },
    [checkForConflict]
  );

  const onSave = useCallback(
    (procedure) => {
      setSubmission((submission) => ({
        ...submission,
        started: true,
      }));
      setIsDirty(false);
      const saveTransaction = apm.startTransaction('procedureEdit.onSave', 'custom');
      attachmentUtil
        .uploadAllFilesFromProcedure(
          procedure,
          /** @type {import('../attachments/types').Attachments} */ (services.attachments)
        )
        .then(() => services.procedures.saveDraft(procedure))
        .then(({ _id }) => onSaveSuccess(_id))
        .catch(onSaveFailure)
        .finally(() => {
          if (saveTransaction) {
            saveTransaction.end();
          }
          if (!isMounted.current) {
            return;
          }
          setSubmission(INITIAL_SUBMISSION_STATE);
        });
    },
    [services.attachments, services.procedures, onSaveSuccess, onSaveFailure]
  );

  // If submission.isSubmitting changes to false, release the submission mutex
  useEffect(() => {
    if (!submission.isSubmitting && releaseSubmissionMutex) {
      releaseSubmissionMutex();
      submissionMutex.current.waitForUnlock().then(() => setSubmissionLocked(false));
    }
  }, [submission.isSubmitting, releaseSubmissionMutex]);

  // This useEffect is here so that the submission state is set before the onSave action is done
  useEffect(() => {
    /*
     * The submission.started flag is needed so that we can know if a submission is underway, but the save action has not been called yet.
     * If we did not have submission.started, the save would run even though a current save was ongoing if a change was made during the ongoing save.
     */
    if (
      submission.isSubmitting &&
      !submission.started &&
      (submission.type === SUBMISSION.SAVE || submission.type === SUBMISSION.AUTOSAVE)
    ) {
      if (submission.type === SUBMISSION.SAVE && autoSaveRef.current) {
        // Cancel a pending autosave if a manual save is done, since the autosave will no longer be needed.
        autoSaveRef.current?.cancel();
      }

      const updated = {
        ...(submission.procedureToSubmit ?? present),
        _rev: procedureRev.current,
      };
      onSave(updated);
    }
  }, [onSave, present, submission, docs.procedure]);

  /**
   * If a review is attempted while submissionMutex is locked, that means a save/autosave is ongoing.
   * In that case, we want a review to be scheduled and run after the save/autosave has completed (this is to allow for
   * the case of the onBlur event that triggers autosave being a review button click event also).
   * Therefore the mutex-protected review callback will run when submissionMutex is available.
   *
   * @type {(settings: import('../api/procedures').ReviewParameters) => void}
   */
  const onMoveToReview = useCallback(
    (settings) => {
      callWithSubmissionMutexWaitForAvailable(() => {
        setSubmission({
          isSubmitting: true,
          type: SUBMISSION.REVIEW,
          settings,
        });
      });
    },
    [callWithSubmissionMutexWaitForAvailable]
  );

  const shouldShowReviewSettingsModal = useMemo(() => {
    return !procedureErrors.hasErrors && Boolean(reviewSettings);
  }, [procedureErrors.hasErrors, reviewSettings]);

  const shouldSubmitReview = useMemo(() => {
    return submission.isSubmitting && submission.type === SUBMISSION.REVIEW;
  }, [submission]);

  const shouldSubmitPreview = useMemo(() => {
    return submission.isSubmitting && submission.type === SUBMISSION.PREVIEW;
  }, [submission]);

  const onBeforeTOCNavigate = useCallback(() => {
    setIsNavigatingInternally(true);
  }, []);

  const shouldPrompt = useMemo(() => {
    // no prompt needed if navigating to review/preview screen or using TOC navigation
    if (shouldSubmitReview || shouldSubmitPreview || isNavigatingInternally || showConflictModal) {
      return false;
    }
    return isDirty;
  }, [shouldSubmitReview, shouldSubmitPreview, isNavigatingInternally, showConflictModal, isDirty]);

  const onProcedureValidationFailure = useCallback(
    (error) => {
      apm.captureError(error ?? 'Error during procedure validation in FormProcedure.');
      setSubmissionErrors(REFRESH_TRY_AGAIN_MESSAGE);
      setSubmission(INITIAL_SUBMISSION_STATE);
    },
    [setSubmissionErrors, setSubmission]
  );

  const redlineFieldList = useMemo(() => {
    if (!present || !docs.redlines) {
      return [];
    }

    return getScrollEntries(present, docs.redlines);
  }, [docs.redlines, present]);

  const goToField = useCallback(
    (fieldEntry) => {
      const { fieldName, headerId, sectionId, stepId } = fieldEntry;
      onScrollToId({ scrollToId: fieldName, headerId, sectionId, stepId });
    },
    [onScrollToId]
  );

  // Use a useMemo here because if the redline_actions array is updated using undo/redo, a resolved redline in the draft should also be undone/redone.
  const unactionedNonCommentRedlines = useMemo(() => {
    if (!docs.redlines || !(present || docs.procedure)) {
      return null; // return null to preserve the distinction between loaded/not-loaded
    }

    /*
     * On the first load, use the initial procedure, else use the present procedure.
     * This needs to be done so that redline actions will be read during the first load--otherwise redlines in the redline_actions array will not be filtered out during the first load.
     */
    return revisionsUtil.getUnactionedNonCommentRedlineDocs((present ?? docs.procedure).redline_actions, docs.redlines);
  }, [docs.procedure, docs.redlines, present]);

  const commentRedlines = useMemo(() => {
    if (!docs.redlines) {
      return null; // return null to preserve the distinction between loaded/not-loaded
    }

    return docs.redlines.filter((redline) => redline.type === REDLINE_TYPE.REDLINE_COMMENT);
  }, [docs.redlines]);

  const unresolvedActionsCount = useMemo(() => {
    if (!(present || docs.procedure) || !unactionedNonCommentRedlines) {
      return 0;
    }
    const procedure = present ?? docs.procedure;
    return revisionsUtil.getUnresolvedActionsCount(procedure, unactionedNonCommentRedlines);
  }, [docs.procedure, present, unactionedNonCommentRedlines]);

  const onProcedureChanged = useCallback(
    (updated, previous) => {
      updatePresentAndAddToPast(updated, previous);
      setIsDirty(true);
    },
    [setIsDirty, updatePresentAndAddToPast]
  );

  /**
   * @type {(settings?: {reviewTypeId?: string}) => void}
   */
  const validateReview = useCallback(async () => {
    try {
      const { updatedProcedure, errors, procedureHasErrors, firstErrorField } =
        await validateUtil.getProcedureValidationResults({
          procedure: present,
          teamId: currentTeamId,
          procedures: selectProcedures(store.getState(), currentTeamId),
          redlines: docs.redlines,
          snippets: docs.snippets,
          options: { showRedlineValidation },
        });
      setSubmissionErrors(null);
      setProcedureErrors({
        errors,
        hasErrors: procedureHasErrors,
        firstErrorField,
      });

      if (updatedProcedure) {
        onProcedureChanged(updatedProcedure, present);
      }

      if (!procedureHasErrors) {
        const settings = await services.procedures.getReviewSettings(present?._id);
        if (settings) {
          setReviewSettings(settings);
        }
      } else {
        setShouldScrollToError(true);
      }
    } catch (error) {
      onProcedureValidationFailure(error);
    } finally {
      setScheduledValidateReview(false);
    }
  }, [
    store,
    currentTeamId,
    showRedlineValidation,
    docs.redlines,
    docs.snippets,
    present,
    onProcedureValidationFailure,
    onProcedureChanged,
    services.procedures,
  ]);

  /**
   * @type {(settings?: {reviewTypeId?: string}) => void}
   */
  const handleValidateReview = useCallback(
    (settings) => {
      setScheduledValidateReview(true);
      if (submissionLocked) {
        if (submission.type === SUBMISSION.REVIEW) {
          return;
        }
      }

      validateReview(settings);
    },
    [submission.type, submissionLocked, validateReview]
  );

  const onReviewFailure = useCallback((error) => {
    apm.captureError(error ?? 'Error during review submission in ProcedureEdit.');
    setSubmissionErrors(REFRESH_TRY_AGAIN_MESSAGE);

    setScheduledValidateReview(false);
    setSubmission(INITIAL_SUBMISSION_STATE);
  }, []);

  const onReviewSuccess = useCallback(
    (response) => {
      if (!isMounted.current) {
        return;
      }
      if (mixpanel) {
        mixpanel.track('Procedure In Review');
      }

      return services.procedures
        .getProcedure(response._id)
        .then((inReview) => {
          const procedureId = procedureUtil.getProcedureId(inReview);
          history.push(procedureReviewPath(currentTeamId, procedureId));
        })
        .catch((error) => {
          onReviewFailure(error);
        });
    },
    [services.procedures, history, mixpanel, currentTeamId, onReviewFailure]
  );

  const onReview = useCallback(
    (procedure, settings) => {
      const reviewTransaction = apm.startTransaction('procedureEdit.onReview', 'custom');
      attachmentUtil
        .uploadAllFilesFromProcedure(
          procedure,
          /** @type {import('../attachments/types').Attachments} */ (services.attachments)
        )
        .then(() => services.procedures.saveReview(procedure, settings))
        .then(onReviewSuccess)
        .catch(onReviewFailure)
        .finally(() => {
          if (reviewTransaction) {
            reviewTransaction.end();
          }
        });
    },
    [services.attachments, services.procedures, onReviewSuccess, onReviewFailure]
  );

  useEffect(() => {
    if (shouldSubmitReview) {
      if (!procedureErrors.hasErrors) {
        // If a throttled autosave has been invoked but the delay timer is still running, cancel the autosave since onValidateReview will save anyway
        invokedAutoSaveTimerRef.current && clearTimeout(invokedAutoSaveTimerRef.current);
        const updated = { ...present, _rev: procedureRev.current };
        onReview(updated, submission.settings);
      } else {
        setSubmission(INITIAL_SUBMISSION_STATE);
        setShouldScrollToError(true);
      }
    }
  }, [
    shouldSubmitReview,
    procedureErrors.hasErrors,
    submission,
    setSubmission,
    onReview,
    present,
    handleOnSave,
    docs.procedure,
  ]);

  const procedureTitle = useMemo(() => {
    if (!present) {
      return '';
    }
    return procedureUtil.getProcedureTitle(present.code, present.name);
  }, [present]);

  const procedureState = useMemo(() => {
    if (!present) {
      return '';
    }

    return present.state;
  }, [present]);

  const collapseAllHeadersAndSections = useCallback(
    (procedure) => {
      const sectionIds = procedure && procedure.sections ? procedure.sections.map((section) => section.id) : [];
      const headerIds = procedure && procedure.headers ? procedure.headers.map((header) => header.id) : [];

      setAllExpanded(false, sectionIds, headerIds);
    },
    [setAllExpanded]
  );

  const collapseAll = useCallback(() => {
    collapseAllHeadersAndSections(present);
  }, [present, collapseAllHeadersAndSections]);

  // Update mounted flag when component is unmounted
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  /**
   * TODO (DEEP): Refactor loading memo to actually convey loading, and not missing documents.
   * Note: We will also need to refactor some of the fetch code to show different errors,
   * right now getProcedure will return undefined/null for any error.
   */
  const loading = useMemo(() => {
    // Wait for procedure and redline data info to load.
    return !docs.procedure || dataLoading || redlinesLoading || !config;
  }, [docs.procedure, dataLoading, redlinesLoading, config]);

  // Fetch procedure
  useEffect(() => {
    if (!services.procedures) {
      return;
    }
    // If procedure has already been fetched, dont fetch again.
    if (docs.procedure) {
      return;
    }

    const draftIndex = procedureUtil.getPendingProcedureIndex(id);
    const loadingProcedures = [services.procedures.getProcedure(id), services.procedures.getProcedure(draftIndex)];
    Promise.allSettled(loadingProcedures)
      .then(([releasedPromise, draftPromise]) => {
        if (!isMounted.current) {
          return;
        }

        const released = releasedPromise.value;
        const draft = draftPromise.value;

        setProcedureLoading(false);

        if (draft) {
          const { editedAt, editedUserId } = draft;
          setShowLock(shouldShowLock({ editedAt, editedUserId, userId }));
        }

        // If the procedure has been archived, redirect user to the procedure view page.
        if (released && released.archived) {
          return history.replace(procedureViewPath(currentTeamId, released._id));
        }

        // Use the draft if one exists, otherwise create one from the release.
        const procedure =
          draft || procedureUtil.newDraft(released, !!config?.auto_procedure_id_enabled, config?.version);

        // Load the procedure collapsed.
        let shouldOpenCollapsed = !FEATURE_EDIT_PROCEDURE_OPENS_EXPANDED;
        if (procedure && procedure.sections.length === 1) {
          shouldOpenCollapsed = false; // expand procedures, like new drafts, that have only one section
        }
        if (procedure && !hasOpenedCollapsed.current && shouldOpenCollapsed) {
          collapseAllHeadersAndSections(procedure);
          hasOpenedCollapsed.current = true;
        }

        procedureRev.current = procedure._rev;
        setDocs((docs) => ({
          ...docs,
          procedure,
        }));
      })
      .catch((error) => {
        if (error.status && error.status !== 404 && error.status !== 409) {
          apm.captureError(error);
        }
      });
  }, [
    id,
    services.procedures,
    history,
    collapseAllHeadersAndSections,
    currentTeamId,
    config?.version,
    config?.auto_procedure_id_enabled,
    docs?.procedure,
    tryUpdateDocWithUniqueId,
    userId,
  ]);

  // Fetch snippets and external data for the first render.
  useEffect(() => {
    // Wait for procedure doc to load
    if (!docs.procedure || !services.settings || !services.externalData || !dataLoading) {
      return;
    }

    // Fetch linked snippets (check for deleted snippets, etc).
    const loadSnippets = async () => {
      const snippetIds = [];
      for (const section of docs.procedure.sections) {
        if (section.snippet_id) {
          snippetIds.push(section.snippet_id);
        }

        for (const step of section.steps) {
          if (step.snippet_id) {
            snippetIds.push(step.snippet_id);
          }
        }
      }
      return services.settings.listSnippets({ includeDeleted: true, snippetIds }).catch(() => {
        // If there was an error, default to keeping all snippets attached.
        return [];
      });
    };

    const loadExternalItems = async () => {
      /**
       * Fetch updated external items and pass them down to FormProcedure for
       * final merging into the next draft. We could update the `procedure` doc
       * here, but that causes problems with effect hooks re-running.
       */
      return services.externalData.getAllExternalItems(docs.procedure).catch(() => {
        // If updating external data failed, fallback gracefully and don't update items.
        return null;
      });
    };

    Promise.all([loadSnippets(), loadExternalItems()])
      .then(([snippets, externalItems]) => {
        if (!isMounted.current) {
          return;
        }
        // Update docs and finish page load
        setDocs((docs) => ({
          ...docs,
          snippets,
          externalItems,
        }));

        setDataLoading(false);
      })
      .catch(() => {});
  }, [id, docs.procedure, docs.redlines, dataLoading, services.procedures, services.settings, services.externalData]);

  // Load redlines every time the edit page is loaded.
  useEffect(() => {
    if (!services.procedures) {
      return;
    }

    // If it's not the first render do not load redlines.
    if (!redlinesLoading) {
      return;
    }

    // Get all unresolved redlines for the procedure.
    services.procedures
      .getUnresolvedRedlineDocs(id)
      .then((redlines) => {
        if (!isMounted.current) {
          return;
        }

        /*
         * Filter out docs that do not have redline ids.
         * This is needed because when redline doc creation code was first released, old clients likely created redline docs from redlines that did not have redline ids.
         */
        const redlinesWithValidRedlineIds = getRedlinesWithValidRedlineIds(redlines);
        // Update docs and finish page load.
        setDocs((docs) => ({
          ...docs,
          redlines: redlinesWithValidRedlineIds,
        }));
        setRedlinesLoading(false);
      })
      .catch(() => {});
  }, [id, redlinesLoading, services.procedures]);

  const submitPreview = useCallback(() => {
    /*
     * If a review is attempted while submissionMutex is locked, that means a save/autosave is ongoing.
     * In that case, we want a review to be scheduled and run after the save/autosave has completed (this is to allow for
     * the case of the onBlur event that triggers autosave being a review button click event also).
     * Therefore the mutex-protected review callback will run when submissionMutex is available.
     */
    callWithSubmissionMutexWaitForAvailable(() => {
      setSubmission({
        isSubmitting: true,
        type: SUBMISSION.PREVIEW,
      });

      setScheduledPreview(false);
    });
  }, [callWithSubmissionMutexWaitForAvailable]);

  const handleOnPreview = useCallback(() => {
    if (submissionLocked) {
      if (submission.type === SUBMISSION.PREVIEW) {
        return;
      }
      setScheduledPreview(true);
    }
    submitPreview();
  }, [submissionLocked, submission.type, submitPreview]);

  const onPreviewSuccess = useCallback(
    (batchSize) => {
      if (!isMounted.current) {
        return;
      }

      if (mixpanel) {
        mixpanel.track('Preview mode engaged');
      }
      const batchSizeParam = batchSize ? `?batchSize=${batchSize}` : '';
      history.push(`${url}/run-preview${batchSizeParam}`);
    },
    [history, mixpanel, url]
  );

  const onStartBatchPreview = useCallback(
    (batchSize) => {
      onPreviewSuccess(batchSize);
      setShowBatchRunModal(false);
    },
    [onPreviewSuccess]
  );

  const onCancelBatchPreview = useCallback(() => {
    setShowBatchRunModal(false);
  }, []);

  const onPreviewFailure = useCallback((error) => {
    apm.captureError(error ?? 'Error during preview submission in ProcedureEdit.');
    setSubmissionErrors(REFRESH_TRY_AGAIN_MESSAGE);
    setSubmission(INITIAL_SUBMISSION_STATE);
  }, []);

  const onPreview = useCallback(
    async (procedure) => {
      const previewTransaction = apm.startTransaction('procedureEdit.onPreview', 'custom');
      try {
        setIsDirty(false);
        await attachmentUtil.uploadAllFilesFromProcedure(
          procedure,
          /** @type {import('../attachments/types').Attachments} */ (services.attachments)
        );
        const { _id: procedureId } = await services.procedures.saveDraft(procedure);
        await getProcedureAndUpdateRev(procedureId);
      } catch (error) {
        setIsDirty(true);
        onPreviewFailure(error);
      } finally {
        if (previewTransaction) {
          previewTransaction.end();
        }
        if (isMounted.current) {
          setSubmission(INITIAL_SUBMISSION_STATE);
        }
      }

      if (isProcedureWithBatchSteps(procedure)) {
        setShowBatchRunModal(true);
      } else {
        onPreviewSuccess();
      }
    },
    [services.attachments, onPreviewSuccess, onPreviewFailure, services.procedures, getProcedureAndUpdateRev]
  );

  useEffect(() => {
    // Allow navigating to preview even if there are validation errors.
    if (shouldSubmitPreview) {
      // If a throttled autosave has been invoked but the delay timer is still running, cancel the autosave since onPreview will save anyway
      invokedAutoSaveTimerRef.current && clearTimeout(invokedAutoSaveTimerRef.current);
      const updated = {
        ...present,
        _rev: procedureRev.current,
      };
      onPreview(updated);
    }
  }, [
    shouldSubmitPreview,
    procedureErrors.hasErrors,
    submission,
    setSubmission,
    onPreview,
    present,
    handleOnSave,
    docs.procedure,
  ]);

  const procedureName = useMemo(() => {
    if (docs.procedure && docs.procedure.name) {
      return docs.procedure.name;
    } else {
      return 'New Procedure';
    }
  }, [docs.procedure]);

  const updateComments = useCallback(
    (procedure) => {
      const updatedProcedure = {
        ...present,
        comments: procedure.comments,
      };
      updatePresentAndAddToPast(updatedProcedure, present);
    },
    [present, updatePresentAndAddToPast]
  );

  const resolveComment = useCallback(
    async (commentId) => {
      const procedureId = docs.procedure && docs.procedure['_id'];
      return services.procedures
        .resolveComment(procedureId, commentId, procedureRev.current)
        .then(() => getProcedureAndUpdateRev(procedureId))
        .then(updateComments);
    },
    [services.procedures, docs.procedure, getProcedureAndUpdateRev, updateComments]
  );

  // Wrapper to ensure the function resolving the comment has the most updated procedure rev
  const getResolveComment = useCallback((commentId) => () => resolveComment(commentId), [resolveComment]);

  const onResolveComment = useCallback(
    (commentId) => {
      return submissionMutex.current.runExclusive(() => getResolveComment(commentId)()).catch(checkForConflict);
    },
    [getResolveComment, checkForConflict]
  );

  const saveReviewComment = useCallback(
    async (comment) => {
      const procedureId = docs.procedure && docs.procedure['_id'];
      return services.procedures
        .addComment(procedureId, comment, procedureRev.current)
        .then(() => getProcedureAndUpdateRev(procedureId))
        .then(updateComments);
    },
    [docs.procedure, services.procedures, getProcedureAndUpdateRev, updateComments]
  );

  // Wrapper to ensure the function saving the comment has the most updated procedure rev
  const getSaveReviewComment = useCallback((comment) => () => saveReviewComment(comment), [saveReviewComment]);

  const onSaveReviewComment = useCallback(
    (comment) => {
      mixpanelTrack('Review Comment Saved on Edit', { level: comment.parent_id ? 'Child' : 'Parent' });
      return submissionMutex.current.runExclusive(() => getSaveReviewComment(comment)()).catch(checkForConflict);
    },
    [mixpanelTrack, getSaveReviewComment, checkForConflict]
  );

  const unresolveComment = useCallback(
    async (commentId) => {
      const procedureId = docs.procedure && docs.procedure['_id'];
      return services.procedures
        .unresolveComment(procedureId, commentId, procedureRev.current)
        .then(() => getProcedureAndUpdateRev(procedureId))
        .then(updateComments);
    },
    [docs.procedure, services.procedures, getProcedureAndUpdateRev, updateComments]
  );

  // Wrapper to ensure the function unresolving the comment has the most updated procedure rev
  const getUnresolveComment = useCallback((commentId) => () => unresolveComment(commentId), [unresolveComment]);

  const onUnresolveComment = useCallback(
    (commentId) => {
      return submissionMutex.current.runExclusive(() => getUnresolveComment(commentId)()).catch(checkForConflict);
    },
    [getUnresolveComment, checkForConflict]
  );

  const redoProcedureState = useCallback(() => {
    setIsDirty(true);
    redoState(present);
  }, [redoState, present, setIsDirty]);

  const undoProcedureState = useCallback(() => {
    setIsDirty(true);
    undoState(present);
  }, [undoState, present, setIsDirty]);

  const isReviewDisabled = useMemo(() => {
    return (
      (submissionLocked && submission.type === SUBMISSION.REVIEW) ||
      scheduledValidateReview ||
      shouldShowReviewSettingsModal
    );
  }, [submissionLocked, submission.type, scheduledValidateReview, shouldShowReviewSettingsModal]);

  const isMoveToReviewDisabled = useMemo(() => {
    return submissionLocked && submission.type === SUBMISSION.REVIEW;
  }, [submissionLocked, submission.type]);

  const isPreviewDisabled = useMemo(() => {
    return (submissionLocked && submission.type === SUBMISSION.PREVIEW) || scheduledPreview;
  }, [scheduledPreview, submission.type, submissionLocked]);

  const onGoToError = useCallback(async () => {
    try {
      const { updatedProcedure, errors, procedureHasErrors, firstErrorField } =
        await validateUtil.getProcedureValidationResults({
          procedure: present,
          teamId: currentTeamId,
          procedures: selectProcedures(store.getState(), currentTeamId),
          redlines: docs.redlines,
          snippets: docs.snippets,
          options: { showRedlineValidation },
        });
      setSubmissionErrors(null);
      setProcedureErrors({
        errors,
        hasErrors: procedureHasErrors,
        firstErrorField,
      });
      if (updatedProcedure) {
        onProcedureChanged(updatedProcedure, present);
      }
      setShouldScrollToError(true);
    } catch (error) {
      onProcedureValidationFailure(error);
    }
  }, [
    currentTeamId,
    docs.redlines,
    docs.snippets,
    onProcedureValidationFailure,
    onProcedureChanged,
    present,
    showRedlineValidation,
    store,
  ]);

  useEffect(() => {
    if (procedureErrors.hasErrors) {
      setShowSubmitError(true);
    } else {
      setShowSubmitError(false);
    }
  }, [setShowSubmitError, procedureErrors.hasErrors]);

  const mergeAllSectionErrors = useCallback(
    (allSectionErrors) => {
      setProcedureErrors((_procedureErrors) => {
        const { errors, procedureHasErrors, firstErrorField } = validateUtil.getMergeSectionValidationResults({
          procedure: present,
          currentProcedureErrors: _procedureErrors.errors,
          updatedAllSectionErrors: allSectionErrors,
          options: { showRedlineValidation },
        });

        return {
          errors,
          hasErrors: procedureHasErrors,
          firstErrorField,
        };
      });
    },
    [present, showRedlineValidation]
  );

  const contextValue = {
    mergeAllSectionErrors,
    onProcedureChanged,
  };

  const onHideConflictModal = () => {
    setShowConflictModal(false);
    setSubmissionErrors(REFRESH_TRY_AGAIN_MESSAGE);
  };
  const hideLock = () => setShowLock(false);

  if (procedureLoading) {
    return null;
  }

  if (!docs.procedure) {
    return <NotFound />;
  }

  if (showLock) {
    const editedUserId = docs.procedure.editedUserId;
    return <ProcedureEditLocked editedUserId={editedUserId} onProceed={hideLock} />;
  }

  return (
    <>
      {/* Sets the document title */}
      <Helmet>
        <title>{`Edit · ${procedureName}`}</title>
      </Helmet>
      {!loading && (
        <>
          <SelectionContextProvider>
            <ProcedureEditProvider
              // @ts-ignore
              value={contextValue}
            >
              <Switch>
                <Route exact path={path}>
                  {showBatchRunModal && (
                    <RunBatchProcedureModal onRun={onStartBatchPreview} onCancel={onCancelBatchPreview} />
                  )}
                  {showConflictModal && <EditConflictModal procedureId={id} onHide={onHideConflictModal} />}
                  <EditToolbar
                    procedureTitle={procedureTitle}
                    procedureState={procedureState}
                    showSubmitError={showSubmitError}
                    onGoToError={onGoToError}
                    submissionErrors={!shouldShowReviewSettingsModal ? submissionErrors : null}
                    isDirty={isDirty}
                    isSubmitting={submission?.isSubmitting}
                    lastSavedTime={lastSavedTime}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    hasPast={hasPast}
                    hasFuture={hasFuture}
                    onUndo={undoProcedureState}
                    onRedo={redoProcedureState}
                    onExpandAll={() => setIsCollapsedMap({})}
                    onCollapseAll={collapseAll}
                    onPreview={handleOnPreview}
                    isPreviewDisabled={isPreviewDisabled}
                    onSave={handleOnSaveButton}
                    isSaveDisabled={submissionLocked}
                    onValidateReview={handleValidateReview}
                    isReviewDisabled={isReviewDisabled}
                    goToRedline={goToField}
                    redlineFieldList={redlineFieldList}
                    unresolvedActionsCount={unresolvedActionsCount}
                  />
                  {shouldShowReviewSettingsModal && (
                    <ReviewSettingsModal
                      onCancel={() => setReviewSettings(null)}
                      onSubmitReview={onMoveToReview}
                      settings={reviewSettings}
                      submissionErrors={shouldShowReviewSettingsModal ? submissionErrors : null}
                      setSubmissionErrors={setSubmissionErrors}
                      isMoveToReviewDisabled={isMoveToReviewDisabled}
                    />
                  )}
                  <div>
                    {/*
                     * TODO: Wrapping a RunContextProvider is a temporary solution. Currently, unable to expand a section
                     * which is referenced in a different step as a dependency. The target in this conditional is the snippet.
                     * Since, it requires access to the getContentBlock function only availible in the RunContextProvider.
                     * We should create a ProcedureEditContext used in conjunction with useProcedureAdapter.
                     */}
                    <RunContextProvider
                      run={docs.procedure || {}}
                      viewMode={undefined}
                      showStepAction={undefined}
                      setShowStepAction={undefined}
                    >
                      <SidebarLayout>
                        <SidebarLayout.Sidebar>
                          <EditSidebar
                            draft={present || docs.procedure}
                            activeSidebarTabs={activeSidebarTabs}
                            updateActiveSidebarTabs={updateActiveSidebarTabs}
                            onBeforeTOCNavigate={onBeforeTOCNavigate}
                            errors={procedureErrors.errors}
                          />
                        </SidebarLayout.Sidebar>
                        <SidebarLayout.Content>
                          <FormProcedure
                            procedure={docs.procedure}
                            snippets={docs.snippets}
                            externalItems={docs.externalItems}
                            procedureErrors={procedureErrors}
                            onSave={handleOnSaveForm}
                            submission={submission}
                            expandCollapse={expandCollapse}
                            setIsCollapsed={setIsCollapsed}
                            onResolveComment={onResolveComment}
                            onUnresolveComment={onUnresolveComment}
                            onSaveReviewComment={onSaveReviewComment}
                            lastSavedTime={lastSavedTime}
                            isDirty={isDirty}
                            currentTab={currentTab} // TODO: EPS-3329 will remove tabs in favor of nested pages
                            stateHistory={stateHistory}
                            onProcedureChanged={onProcedureChanged}
                            promptBeforeUnload={shouldPrompt}
                            shouldScrollToError={shouldScrollToError}
                            setShouldScrollToError={setShouldScrollToError}
                            onScrollToRefChanged={onScrollToRefChanged}
                            onScrollToId={onScrollToId}
                            unactionedRedlines={unactionedNonCommentRedlines}
                            commentRedlines={commentRedlines}
                          />
                        </SidebarLayout.Content>
                      </SidebarLayout>
                    </RunContextProvider>
                  </div>
                </Route>
                <Route path={`${path}/run-preview`}>
                  <Run previewMode={PREVIEW_MODE.EDIT} />
                </Route>
              </Switch>
            </ProcedureEditProvider>
          </SelectionContextProvider>
        </>
      )}
    </>
  );
};

export default ProcedureEdit;
