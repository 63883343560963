import {
  equal as mathJsEqual,
  unequal as mathJsUnequal,
  smaller as mathJsSmaller,
  smallerEq as mathJsSmallerEq,
  larger as mathJsLarger,
  largerEq as mathJsLargerEq,
  MathType,
  MathCollection,
} from 'mathjs';

const SUPPORTED_OPERATIONS = ['=', '≠', '<', '>', '≤', '≥'] as const;
export type SupportedOperation = (typeof SUPPORTED_OPERATIONS)[number];

const isSupportedOperation = (op: SupportedOperation | string): boolean => {
  return SUPPORTED_OPERATIONS.includes(op as SupportedOperation);
};

const JS_RELATIONAL_FUNCTION: {
  [op in SupportedOperation]: (
    a: string | boolean,
    b: string | boolean
  ) => boolean;
} = {
  '=': (a, b) => a === b,
  '≠': (a, b) => a !== b,
  '<': (a, b) => a < b,
  '>': (a, b) => a > b,
  '≤': (a, b) => a <= b,
  '≥': (a, b) => a >= b,
};

const MATH_JS_RELATIONAL_FUNCTION: {
  [op in SupportedOperation]: (
    x: MathType | string,
    y: MathType | string
  ) => boolean | MathCollection;
} = {
  '=': mathJsEqual,
  '≠': mathJsUnequal,
  '<': mathJsSmaller,
  '>': mathJsLarger,
  '≤': mathJsSmallerEq,
  '≥': mathJsLargerEq,
};

const _evaluateBasic = (
  lhs: string | boolean,
  rhs: string | boolean,
  op: SupportedOperation
): boolean | null => {
  const relationalFunction = JS_RELATIONAL_FUNCTION[op];
  return relationalFunction ? relationalFunction(lhs, rhs) : null;
};

/**
 * Evaluates math expression of the form "lhs op rhs."
 * Uses mathjs's built-in relative and absolute tolerance for comparing
 * floating point values.
 *
 * E.g., lhs = 1, rhs = 2, and op = '<', evaluates to true.
 *
 * @param lhs - left hand side of the expression
 * @param rhs - right hand side of the expression
 * @param op - math operation to perform
 *
 * @returns true if the expression is true
 */
const evaluate = (
  lhs: number | string | boolean,
  rhs: number | string | boolean,
  op: SupportedOperation
): boolean | null => {
  // Mathjs number functions cannot accept a boolean, and do not properly compare strings.
  if (
    typeof lhs === 'boolean' ||
    typeof rhs === 'boolean' ||
    (typeof lhs === 'string' && typeof rhs === 'string')
  ) {
    return _evaluateBasic(lhs as boolean | string, rhs as boolean | string, op);
  }

  // Evaluate as a number.
  const mathJsRelationalFunction = MATH_JS_RELATIONAL_FUNCTION[op];
  return mathJsRelationalFunction
    ? (mathJsRelationalFunction(lhs, rhs) as boolean)
    : null;
};

type Range = {
  min: number;
  max: number;
};

/**
 * Evaluates if value lies in range, excluding the endpoints of
 * the range.
 *
 * E.g., value = 1, min = 0, max = 1 evaluates to false.
 *
 * @param value - value to check
 * @param range - range to check
 * @param range.min - lower bound
 * @param range.max - upper bound
 *
 * returns true if value lies in range
 */
const evaluateRangeExclusive = (
  value: number | string | boolean,
  range: Range
): boolean => {
  if (typeof (range.min as unknown as string) === 'string') {
    range.min = parseFloat(range.min as unknown as string);
  }
  if (typeof (range.max as unknown as string) === 'string') {
    range.max = parseFloat(range.max as unknown as string);
  }
  return (
    Boolean(evaluate(value, range.min, '>')) &&
    Boolean(evaluate(value, range.max, '<'))
  );
};

export { isSupportedOperation, evaluate, evaluateRangeExclusive };
