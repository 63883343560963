import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { CouchLikeOperation } from 'shared/lib/types/operations';
import { Release, Run } from 'shared/lib/types/views/procedures';
import { useHistory, useParams } from 'react-router-dom';
import RunStatusLabel from '../components/RunStatusLabel';
import { DatabaseServices } from '../contexts/proceduresSlice';
import { Helmet } from 'react-helmet-async';
import OperationEventList from '../components/Operations/OperationEventList';
import LoadingScreen from '../components/LoadingScreen';
import ViewModeSelect from '../schedule/components/ViewModeSelect';
import { Event, Swimlane } from 'shared/schedule/types/event';
import Gantt from '../schedule/components/Gantt';
import { DateTime, Interval } from 'luxon';
import Calendar from '../schedule/components/Calendar';
import Button, { BUTTON_TYPES } from '../components/Button';
import { useAuth } from '../contexts/AuthContext';
import { PERM } from '../lib/auth';
import OperationFormModal, { OperationFormValues, SaveResult } from '../components/Operations/OperationFormModal';
import labels from 'shared/lib/labelUtil';
import { operationViewPath, operationsDashboardPath } from '../lib/pathUtil';
import OperationUtil from '../lib/operationUtil';
import { useMixpanel } from '../contexts/MixpanelContext';
import ThreeDotMenu from '../elements/ThreeDotMenu';
import { MenuContextAction } from '../components/MenuContext';
import _ from 'lodash';
import useCreateEventModal from '../schedule/hooks/useCreateEventModal';

export const END_OPERATION_MESSAGE = 'Are you sure you want to end this operation?';
export const CANNOT_START_EMPTY_OPERATION_MESSAGE = 'Please add at least one procedure to start this operation.';
export const CANNOT_END_OPERATION_WITH_RUNNING_PROCEDURES_MESSAGE =
  'This operation has unstarted or running procedures and cannot be ended.';

const DEFAULT_PERIOD_BUFFER = { days: 1 };
const PERIOD_BUFFER_PERCENT = 0.1;
const INITIAL_TIME_SCALE = 0.00001;
const MAIN_VERTICAL_PADDING = 160;
const HEADER_FOOTER_PADDING = 40;

const OperationDetail = () => {
  const { auth } = useAuth();
  const history = useHistory();

  const { mixpanel } = useMixpanel();
  const mixpanelTrack = useCallback(
    (trackingKey: string, properties?: object | undefined) => {
      if (mixpanel) {
        mixpanel.track(trackingKey, properties);
      }
    },
    [mixpanel]
  );

  const { id } = useParams<{ id: string }>();
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const [viewMode, setViewMode] = useState<string>('list');

  const [operation, setOperation] = useState<CouchLikeOperation | undefined>();
  const [procedures, setProcedures] = useState<Array<Release>>([]);
  const [runs, setRuns] = useState<Array<Run>>([]);
  const [loadingRows, setLoadingRows] = useState(true);

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [endOperationDisabled, setEndOperationDisabled] = useState(true);

  const [events, setEvents] = useState<Array<Event> | undefined>();
  const [swimlanes, setSwimlanes] = useState<Array<Swimlane> | undefined>();
  const [error, setError] = useState('');

  const headerAreaRef = useRef<HTMLDivElement>(null);

  const { displayCreateEventModal, CreateEventModal, eventSaved } = useCreateEventModal();

  const loadEvents = useCallback(() => {
    Promise.all([services.events.getOperationEvents(decodeURIComponent(id)), services.swimlanes.getSwimlanes()])
      .then(([newEvents, newSwimlanes]) => {
        setEvents(newEvents);
        setSwimlanes(newSwimlanes);
      })
      .catch(() => {
        setError('Error loading schedule - please refresh and try again.');
      });
  }, [id, services.events, services.swimlanes]);

  useEffect(() => {
    loadEvents();
  }, [services.events, services.swimlanes, id, loadEvents, eventSaved]);

  const operationPeriod: Interval = useMemo(() => {
    const scheduledEvents = events?.filter((e) => e.start) ?? [];
    if (scheduledEvents.length === 0) {
      return Interval.fromDateTimes(
        DateTime.utc().minus(DEFAULT_PERIOD_BUFFER),
        DateTime.utc().plus(DEFAULT_PERIOD_BUFFER)
      );
    }
    const firstStart = DateTime.min(...scheduledEvents.map((e) => e.start));
    let lastEnd = DateTime.max(...scheduledEvents.map((e) => e.end || e.start));
    if (operation && operation.state === 'running') {
      lastEnd = DateTime.max(lastEnd, DateTime.utc());
    }
    const durationMillis = Interval.fromDateTimes(firstStart, lastEnd).toDuration().toMillis();
    const bufferMillis = durationMillis * PERIOD_BUFFER_PERCENT;
    return Interval.fromDateTimes(firstStart.minus(bufferMillis), lastEnd.plus(bufferMillis));
  }, [events, operation]);

  const loadOperation = useCallback(() => {
    services.settings.getOperation(decodeURIComponent(id)).then(setOperation);
  }, [id, services.settings]);

  useEffect(() => {
    loadOperation();
  }, [id, operation?.state, services.settings, loadOperation, events]);

  useEffect(() => {
    if (!operation || !operation.key) {
      return;
    }

    const procedureIds = operation.procedures.map((p) => ({ id: p.id })).filter((p) => !!p.id);
    const proceduresPromise =
      procedureIds.length === 0
        ? Promise.resolve({ procedures: [] })
        : services.procedures.bulkGetProcedures(procedureIds);
    const runsPromise = services.runs.getRunsByOperation(operation.key);
    Promise.all([proceduresPromise, runsPromise]).then(([{ procedures }, runs]) => {
      setProcedures(procedures as Array<Release>);
      setRuns(runs);
      setLoadingRows(false);
    });
  }, [operation, services.procedures, services.runs]);

  const refreshRuns = useCallback(() => {
    if (!operation || !operation.key) {
      return;
    }

    if (operation.state === 'planning') {
      return;
    }

    services.runs.getRunsByOperation(operation.key).then((runs) => setRuns(runs));
  }, [operation, services.runs]);

  useEffect(() => {
    setProcedures([]);
    setRuns([]);
  }, [id]);

  useEffect(() => {
    if (!operation || !operation.key) {
      return;
    }

    const observer = services.runs.onRunsByOperationChanged(operation.key, refreshRuns);

    return () => {
      if (!observer) {
        return;
      }
      observer.cancel();
    };
  }, [services.runs, operation, services.procedures, refreshRuns]);

  const operationUtil = useMemo(() => {
    return new OperationUtil(operation?.procedures || [], runs);
  }, [operation?.procedures, runs]);

  useEffect(() => {
    setEndOperationDisabled(
      operationUtil.hasUnstartedProcedures() || runs.filter((run) => run.state !== 'completed').length > 0
    );
  }, [operationUtil, runs]);

  const duplicateOperation = useCallback(
    (values: OperationFormValues): Promise<SaveResult> => {
      if (!operation) {
        return Promise.resolve({
          success: false,
        });
      }

      setIsDuplicating(true);

      const duplicateOperation: CouchLikeOperation = {
        key: labels.getLabelKey(values.name),
        name: values.name.trim(),
        state: 'planning',
        description: values.description?.trim(),
        procedures: operationUtil.getAllProcedures(),
        events: events?.map((event) => ({
          ..._.omit(event, 'run_id'),
          status: 'planning',
        })),
      };

      return services.settings
        .saveOperation(duplicateOperation)
        .then(() => mixpanel?.track('Duplicate Operation'))
        .then(() => setShowDuplicateModal(false))
        .then(() => history.push(operationViewPath(currentTeamId, duplicateOperation.key)))
        .then(() => ({ success: true }))
        .catch((err) => ({
          success: false,
          message: err.message,
        }))
        .finally(() => setIsDuplicating(false));
    },
    [currentTeamId, events, history, mixpanel, operation, operationUtil, services.settings]
  );

  const startOperation = useCallback(async () => {
    if (!operation) {
      return Promise.resolve();
    }

    try {
      const results = await services.settings.startOperation(operation.name);
      operation.state = 'running';
      mixpanelTrack('Start Operation', { Source: 'Operation Detail' });
      return results;
    } catch {
      // ignore
    } finally {
      loadOperation();
    }
  }, [mixpanelTrack, operation, services, loadOperation]);

  const endOperation = useCallback(async () => {
    if (!window.confirm(END_OPERATION_MESSAGE) || !operation) {
      return Promise.resolve();
    }

    try {
      const results = await services.settings.endOperation(operation.name);
      mixpanelTrack('End Operation', { Source: 'Operation Detail' });
      history.push(operationsDashboardPath(currentTeamId));
      return results;
    } catch {
      // ignore
    }
  }, [history, mixpanelTrack, operation, services, currentTeamId]);

  const menuActions: Array<MenuContextAction> = [
    {
      type: 'label',
      label: 'Duplicate Operation',
      data: {
        icon: 'copy',
        title: 'Duplicate aaOperation',
        onClick: () => setShowDuplicateModal(true),
        disabled: isDuplicating,
      },
    },
  ];
  if (operation && operation.state === 'planning') {
    menuActions.push({
      type: 'label',
      label: 'Start Operation',
      data: {
        icon: 'play',
        title: procedures.length === 0 ? CANNOT_START_EMPTY_OPERATION_MESSAGE : 'Start Operation',
        onClick: startOperation,
        disabled: procedures.length === 0,
      },
    });
  }
  if (operation && operation.state === 'running') {
    menuActions.push({
      type: 'label',
      label: 'End Operation',
      data: {
        icon: 'stop',
        title: endOperationDisabled ? CANNOT_END_OPERATION_WITH_RUNNING_PROCEDURES_MESSAGE : 'End Operation',
        onClick: endOperation,
        disabled: endOperationDisabled,
      },
    });
  }

  const addEvent = useCallback(async () => {
    if (!operation) {
      return;
    }
    mixpanelTrack('Add Event', { Source: 'Operation Detail' });
    displayCreateEventModal({ operation });
  }, [mixpanelTrack, operation, displayCreateEventModal]);

  const usedVerticalSpace = () => {
    if (headerAreaRef && headerAreaRef?.current) {
      return headerAreaRef?.current?.clientHeight + HEADER_FOOTER_PADDING;
    }
    return MAIN_VERTICAL_PADDING;
  };

  if (!operation || loadingRows || !events || !swimlanes) {
    return <LoadingScreen />;
  }

  const mainContent = () => {
    if (error && viewMode !== 'list') {
      return (
        <div className="flex h-full items-center justify-center">
          <span className="text-red-700">{error}</span>
        </div>
      );
    }
    switch (viewMode) {
      case 'gantt':
        return (
          <Gantt period={operationPeriod} events={events} swimlanes={swimlanes} initialTimeScale={INITIAL_TIME_SCALE} />
        );
      case 'list':
        return (
          <OperationEventList
            operation={operation}
            procedures={procedures}
            setProcedures={setProcedures}
            runs={runs}
            events={events}
            loadEvents={loadEvents}
            usedVerticalSpace={usedVerticalSpace}
          />
        );
      case 'cal':
        return <Calendar period={operationPeriod} events={events} />;
    }
  };

  return (
    <div className="flex flex-col px-5 py-6 w-full h-full">
      <Helmet>
        <title>Planning - Operation Details</title>
      </Helmet>

      <CreateEventModal />

      {showDuplicateModal && (
        <OperationFormModal
          type="duplicate"
          operation={operation}
          onPrimaryAction={duplicateOperation}
          onSecondaryAction={() => setShowDuplicateModal(false)}
        />
      )}

      <div ref={headerAreaRef} className="flex flex-col w-full z-[100]">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row space-x-2 items-center">
            <ViewModeSelect viewMode={viewMode} setViewMode={setViewMode} unscheduledEnabled={false} />
            <span className="text-lg font-semibold line-clamp-1">{operation.name}</span>
            <RunStatusLabel statusText={operation.state} size="sm" />
          </div>

          <div className="flex flex-row space-x-2 ml-4">
            {auth.hasPermission(PERM.RUNS_EDIT) && operation.state !== 'ended' && (
              <Button type={BUTTON_TYPES.PRIMARY} leadingIcon="plus" onClick={addEvent} title="Add Event">
                Add Event
              </Button>
            )}

            {auth.hasPermission(PERM.RUNS_EDIT) && (
              <ThreeDotMenu menuActions={menuActions} menuLabel="Operation Menu" />
            )}
          </div>
        </div>
        <div className="text-gray-500 m-3 line-clamp-3">{operation.description}</div>
      </div>

      {mainContent()}
    </div>
  );
};

export default OperationDetail;
